import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../components/Layout';
import Cover from '../../components/Elements/Cover';
import PageSection from '../../components/Elements/PageSection';
import Row, { Col } from '../../components/Elements/Grid';

import GetAJob from '../../components/Parts/GetAJob';
import getYears from '../../helpers/getYears';
import Image from '../../components/Elements/Image';
import withTranslations from '../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('overOns');

  return (
    <Layout>
      <Seo title={t('seoTitle')} />
      <Cover image="avocado" nowrap>
        <Image
          image="headerOverOns"
          className="cover-photo"
          style={{ position: 'fixed' }}
          imgStyle={{ objectPosition: 'center center' }}
        />
        <div className="wrapped">
          <PageSection center>
            <h1>{t('intro.title')}</h1>
            <p>{t('intro.description.p1', { years: getYears() })}</p>
            <p>{t('intro.description.p2')}</p>
            <p>{t('intro.description.p3')}</p>
          </PageSection>
          <PageSection grid slide>
            <h2 className="text-center">{t('also.title')}</h2>
            <Row>
              <Col story>
                <div className="title">
                  <Image
                    image="pagesOverOnsFriet"
                    style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                    }}
                    imgStyle={{
                      objectPosition: 'center 65%',
                    }}
                  />
                </div>
                <div className="story-content">
                  <h3>{t('also.fries.title')}</h3>
                  <p>{t('also.fries.description')}</p>
                </div>
              </Col>
              <Col story>
                <div className="title">
                  <Image image="pagesOverOnsGameRoom" />
                </div>
                <div className="story-content">
                  <h3>{t('also.gameRoom.title')}</h3>
                  <p>{t('also.gameRoom.description')}</p>
                </div>
              </Col>
              <Col story>
                <div className="title">
                  <Image
                    image="pagesOverOnsHackathon"
                    style={{
                      position: 'absolute',
                      height: '100%',
                      width: '100%',
                    }}
                    imgStyle={{
                      objectPosition: 'center 65%',
                    }}
                  />
                </div>
                <div className="story-content">
                  <h3>{t('also.hackathon.title')}</h3>
                  <p>{t('also.hackathon.description')}</p>
                </div>
              </Col>
            </Row>
          </PageSection>
          <GetAJob title={t('getAJobTitle')} />
        </div>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
